import React, {Component} from 'react';
import {connect} from 'react-redux';
import Cookies from 'cookies-js';
import DatePickerInput from '../fields/date-picker-input';
import ButtonLink from '../blocks/button-link';
import moment from 'moment';
import QuickBooksReportForm from "./QuickBooksReportForm";
import SiteHelper from '../../utilities/site-manager-helper';

class Reports extends Component {

   constructor(props) {
      super(props);
      this.state = {
         to: null,
         from: null,
         mat_to_date: null
      }
   }

   toChange = (event, value) => {
      this.setState({to: moment(value).format('YYYY-MM-DD')});
   }

   fromChange = (event, value) => {
      this.setState({from: moment(value).format('YYYY-MM-DD')});
   }

   onMatDateChange = (event, value) => {
      this.setState({mat_to_date: moment(value).format('YYYY-MM-DD')});
   }

   render() {
      const { terms } = SiteHelper.getTerms();

      return (
         <div className="missionary-expense-report-container">
            <div className="expense-report-form-wrap">
               <div className="header">
                  <h1>{terms.field_worker} Expense Reports</h1>
               </div>
               <div className="form-section">
                  <DatePickerInput
                     id="employee_payroll_date_from"
                     floatingLabelText="From Date"
                     onChange={this.fromChange}
                  />
                  <DatePickerInput
                     id="employee_payroll_date_to"
                     floatingLabelText="To Date"
                     onChange={this.toChange}
                  />
                  <ButtonLink
                     backgroundColor="#1E88E5"
                     labelColor="#ffffff"
                     label="Download"
                     to={
                        process.env.REACT_APP_API_ROOT +
                        '/expnese-request-report/missionaries?from=' + this.state.from +
                        '&to=' + this.state.to +
                        '&api_token=' + Cookies.get('token')
                     }
                     disabled={this.state.from && this.state.to ? false : true}
                     blank_target={true}
                     style={{marginTop: '20px'}}
                  />
               </div>    
            </div>
           <QuickBooksReportForm />
         </div>
      );
   }
}
export default connect(
    state => ({
      expenseReports: state.rootReducer.expenseReports
    })
)(Reports);
