import React from 'react';
import { connect } from 'react-redux';
import {
    selectAdminFee,
    selectAdvanceRequests,
    selectHoldAccounts,
    selectHousingAllowance,
    selectInsurancePayment, selectManualMinistryAdvance,
    selectManualMinistryReimbursement,
    selectMidMonthDisbursement,
    selectMinistryReimbursement,
    selectReimbursedAmount,
    selectTotalExpenses,
    selectTotalPayroll,
    selectWireFee
} from "../../../reducers/distribution-form";
import {
    approveAdvanceRequest,
    setAdvanceRequestAmountApproved,
    addHoldAccountTransaction,
    deleteHoldAccountTransaction,
    setMidMonthDisbursement,
    setWireFee,
    setHousingAllowance,
    setInsurancePayment,
    setManualMinistryAdvance,
    setManualMinistryReimbursement,
} from "../../../actions/distribution-form";
import SiteHelper from '../../../utilities/site-manager-helper';
import FontIcon from "material-ui/FontIcon";
import DepositDialog from './HoldAccountTransactionDialog';
import ExpenseListDialog from './ExpenseListDialog';
import AdvanceRequestDialog from './AdvanceRequestDialog';
import IconButton from 'material-ui/IconButton';
import Icon from '../icon';
import CurrencyInput from "../../fields/CurrencyInput";

class Expenses extends React.PureComponent {
    state = {
        depositDialogOpen: false,
        expenseListOpen: false,
        advanceRequestDialogOpen: false,
    };

    advanceRequestAmount = () => Object.values(this.props.advanceRequests || {})
        .reduce((sum, x) => (sum + (x.amount_approved || 0)), 0);

    holdAccountsDepositsAmount = () => {
        const { holdAccounts = {} } = this.props;
        return Object.values(holdAccounts || {}).reduce((sum, x) => (sum + (x.totalDepositFromGeneralFundAmount || 0)), 0);
    };

    totalExpenses = () => {
        const { totalExpenses, reimbursedAmount } = this.props;
        if (typeof reimbursedAmount === 'number' && !isNaN(reimbursedAmount)) return totalExpenses + reimbursedAmount;
        return totalExpenses;
    };

    render = () => {
        const { readOnly } = this.props;
        const { terms } = SiteHelper.getTerms();
        const styles = {
            outer: {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
            },
            tableContainer: {
                padding: 20
            },
            tableContainerHeader: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            },
            input: {
                width: '130px',
            },
            inputContainer: {
                margin: 15,
            }
        };

        const AddIcon = (<FontIcon className="material-icons">add</FontIcon>);

        return (
            <div>
                <div style={styles.outer}>
                    <CurrencyInput
                        id="total_payroll"
                        floatingLabelText="Total Payroll"
                        value={this.props.totalPayroll || 0}
                        underlineShow={false}
                        disabled={true}
                        style={{ ...styles.input, ...{ width: '110px' } }}
                        containerStyle={styles.inputContainer}
                    />
                    { AddIcon }
                    <CurrencyInput
                        id="admin_fee"
                        floatingLabelText={terms.locale + " Admin Fee"}
                        value={this.props.adminFee || 0}
                        underlineShow={false}
                        disabled={true}
                        style={styles.input}
                        containerStyle={styles.inputContainer}
                    />
                    { AddIcon }
                    <CurrencyInput
                        id="wire_fee"
                        floatingLabelText="Wire Fee"
                        value={this.props.wireFee || 0}
                        disabled={readOnly}
                        style={{ ...styles.input, ...{ width: '100px' } }}
                        containerStyle={styles.inputContainer}
                        onChange={this.props.setWireFee}
                    />
                    { AddIcon }
                    <CurrencyInput
                        id="insurance_payment"
                        floatingLabelText="Insurance Payment"
                        value={this.props.insuranceAmount || 0}
                        disabled={readOnly}
                        style={{ ...styles.input, ...{ width: '140px' } }}
                        containerStyle={styles.inputContainer}
                        onChange={this.props.setInsurancePayment}
                    />
                    { AddIcon }
                    <CurrencyInput
                        id="housing_allowance"
                        floatingLabelText="Housing Allowance"
                        value={this.props.housingAllowance || 0}
                        disabled={readOnly}
                        style={{ ...styles.input, ...{ width: '140px' } }}
                        containerStyle={styles.inputContainer}
                        onChange={this.props.setHousingAllowance}
                    />
                    { AddIcon }
                    <CurrencyInput
                        id="held_liability_account_deposit"
                        floatingLabelText="Held Accounts Deposit"
                        value={this.holdAccountsDepositsAmount()}
                        underlineShow={false}
                        disabled={true}
                        style={{ ...styles.input, ...{ width: '230px' }}}
                        containerStyle={styles.inputContainer}
                    />
                    { AddIcon }
                    <CurrencyInput
                        id="ministry_reimbursement"
                        floatingLabelText={terms.business + " Reimbursement"}
                        value={this.props.ministryReimbursement || 0}
                        underlineShow={false}
                        disabled={true}
                        style={{ ...styles.input, ...{ width: '190px' }}}
                        containerStyle={styles.inputContainer}
                        inputSuffix={
                            <IconButton onClick={() => this.setState({ expenseListOpen: true })}>
                                <Icon type="info_outline" />
                            </IconButton>
                        }
                    />
                    { AddIcon }
                    <CurrencyInput
                      id="manual_ministry_reimbursement"
                      floatingLabelText={"Manual " + terms.business + " Reimbursement"}
                      value={this.props.manualMinistryReimbursement || 0}
                      disabled={readOnly}
                      style={{ ...styles.input, ...{ width: '240px' }}}
                      containerStyle={styles.inputContainer}
                      onChange={this.props.setManualMinistryReimbursement}
                    />
                    { AddIcon }
                    <CurrencyInput
                        id="ministry_advance"
                        floatingLabelText={terms.business + " Advance"}
                        value={this.advanceRequestAmount() || 0}
                        underlineShow={false}
                        disabled={true}
                        style={styles.input}
                        containerStyle={styles.inputContainer}
                        inputSuffix={
                            <IconButton onClick={() => this.setState({ advanceRequestDialogOpen: true })}>
                                <Icon type="info_outline" />
                            </IconButton>
                        }
                    />
                    { AddIcon }
                    <CurrencyInput
                      id="manual_ministry_advance"
                      floatingLabelText={"Manual " + terms.business + " Advance"}
                      value={this.props.manualMinistryAdvance || 0}
                      disabled={readOnly}
                      style={{ ...styles.input, ...{ width: '210px' }}}
                      containerStyle={styles.inputContainer}
                      onChange={this.props.setManualMinistryAdvance}
                    />
                    { AddIcon }
                    <CurrencyInput
                      id="mid_month_disbursement"
                      floatingLabelText="Mid Month Disbursement"
                      value={this.props.midMonthDisbursement || 0}
                      style={{ ...styles.input, ...{ width: '190px' } }}
                      containerStyle={styles.inputContainer}
                      onChange={this.props.setMidMonthDisbursement}
                      disabled={readOnly}
                    />
                    <FontIcon className="material-icons">drag_handle</FontIcon>
                    <CurrencyInput
                        id="total_expenses"
                        floatingLabelText="Total Expenses"
                        value={this.totalExpenses() || 0}
                        underlineShow={false}
                        disabled={true}
                        style={styles.input}
                        containerStyle={styles.inputContainer}
                    />
                </div>
                <DepositDialog
                    holdAccounts={Object.values(this.props.holdAccounts || {})}
                    transactionType="Deposit"
                    open={this.state.depositDialogOpen}
                    onClose={() => this.setState({ depositDialogOpen: false })}
                    onSubmit={deposit => {
                        this.setState({ depositDialogOpen: false });
                        this.props.addHoldAccountTransaction({
                            ...deposit,
                            type: 'Deposit',
                            fromGeneralFund: true,
                        });
                    }}
                />
                <ExpenseListDialog
                    open={this.state.expenseListOpen}
                    onClose={() => this.setState({ expenseListOpen: false })}
                />
                <AdvanceRequestDialog
                    open={this.state.advanceRequestDialogOpen}
                    onClose={() => this.setState({ advanceRequestDialogOpen: false })}
                />
            </div>
        )
    }
}

export default connect(state => {
    return {
        advanceRequests: selectAdvanceRequests(state),
        totalPayroll: selectTotalPayroll(state),
        adminFee: selectAdminFee(state),
        wireFee: selectWireFee(state),
        insuranceAmount: selectInsurancePayment(state),
        housingAllowance: selectHousingAllowance(state),
        holdAccounts: selectHoldAccounts(state),
        ministryReimbursement: selectMinistryReimbursement(state),
        totalExpenses: selectTotalExpenses(state),
        reimbursedAmount: selectReimbursedAmount(state),
        midMonthDisbursement: selectMidMonthDisbursement(state),
        manualMinistryReimbursement: selectManualMinistryReimbursement(state),
        manualMinistryAdvance: selectManualMinistryAdvance(state),
    };
}, {
    approveAdvanceRequest,
    setAdvanceRequestAmountApproved,
    addHoldAccountTransaction,
    deleteHoldAccountTransaction,
    setMidMonthDisbursement,
    setWireFee,
    setHousingAllowance,
    setInsurancePayment,
    setManualMinistryReimbursement,
    setManualMinistryAdvance,
})(Expenses);
